<template>
  <div class="bg--log">
    <div class="container container--header">
      <div class="header">
        <a href="/" class="header__logo header__logo--log"></a>
      </div>
    </div>
    <div class="container">
      <div class="login-block">
        <div class="login-block__icon"></div>
        <div class="login-block__wrapper">
          <h1 class="login__tit">
            LETIQUE <span>Мakeup</span>
          </h1>
          <p class="login__txt">
            Теория и практика
          </p>
          <form class="login-form">
            <div class="login-form__blocks">
              <div class="login-form__block">
                <span class="login-form__block-icon"></span>
                <input type="email"
                  class="login-form__input"
                  placeholder="Укажите ваш email"
                  v-model="user.email"
                >
              </div>
              <div class="login-form__block">
                <span class="login-form__block-icon login-form__block-icon--2"></span>
                <input :type="showPassword ? 'text' : 'password'"
                  class="login-form__input" placeholder="Пароль"
                  v-model="user.password"
                >
                <button class="login-form__hide" @click.prevent="showPassword = !showPassword" />
              </div>
            </div>
            <div class="button__block">
              <button class="login-form__button" @click.prevent="login" :disabled="loading">
                {{ loading ? 'Загрузка...' : 'Войти' }}
              </button>
              <span class="error" v-if="error">{{ error }}</span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showPassword: false,
      user: {
        email: null,
        password: null,
        guide: 13,
      },
      error: null,
      loading: false,
    };
  },
  mounted() {
    if (this.$route.query.email) {
      this.user.email = this.$route.query.email;
      this.user.password = this.$route.query.password;
      this.login();
    }
  },
  methods: {
    async login() {
      this.loading = true;
      this.error = '';
      const response = await this.$request.post('/api/guide/login', this.user);
      if (response.status !== 200 || !response.data.success) {
        this.error = 'Неправильный логин или пароль';
      } else {
        localStorage.setItem('token', response.data.result.token);
        this.$router.replace('/');
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.error {
  color: #f00;
  display: block;
  font-size: 1.6rem;
}
</style>
